import isPlainObject from "lodash/isPlainObject";
import pickBy from "lodash/pickBy";

import { logError } from "./common";
import { config } from "./config";

const GRAYLOG_URL = "https://staging.services.cluster.veloconnect.io/graylog-proxy/gelf";
const GRAYLOG_SERVICE = "buschjost-zebra";

const GRAYLOG_LOG_LEVEL = {
  EMERGENCY: 0,
  ALERT: 1,
  CRITICAL: 2,
  ERROR: 3,
  WARNING: 4,
  NOTICE: 5,
  INFO: 6,
  DEBUG: 7
};

export class Graylog {
  static async log(message, {level = GRAYLOG_LOG_LEVEL.INFO, fullMessage, error, ...additionalFields}) {
    try {
      if (!config.isGraylogEnabled) {
        return;
      }

      const data = {
        version: "1.1",
        host: config.HOST,
        service: GRAYLOG_SERVICE,
        timestamp: Date.now() / 1000,
        level
      };

      if (typeof message === "object") {
        data.short_message = JSON.stringify(message);
      } else {
        data.short_message = message;
      }

      if (fullMessage) {
        if (typeof fullMessage === "object") {
          data.full_message = JSON.stringify(fullMessage);
        } else {
          data.full_message = fullMessage;
        }
      }

      if (error) {
        if (error.stack && error.message) {
          data.full_message = error.message;

          /*
           * Extract error file and line.
           */

          const firstStackLine = error.stack.split("\n")[0];
          const [file, line] = firstStackLine.substring(firstStackLine.indexOf("("), firstStackLine.indexOf(")")).split(":");

          data.file = file;
          data.line = line;

          additionalFields.stack = error.stack;
        } else if (isPlainObject(error)) {
          data.full_message = JSON.stringify(error);
          additionalFields.error = error;
        } else {
          data.full_message = error.toString();
        }
      }

      if (isPlainObject(additionalFields)) {
        for (const field in additionalFields) {
          additionalFields["_" + field] = additionalFields[field];
        }
      }

      Object.assign(data, additionalFields);

      await fetch(GRAYLOG_URL, {
        method: "POST",
        body: JSON.stringify(pickBy(data))
      })
        .then(response => {
          if (!response.ok) {
            logError(`Fetch error: ${response.statusText}`);
          }
        })
        .catch(error => {
          logError(`Fetch error: ${error.toString()}`);
        });
    } catch (error) {
      logError(`Error on sending log to Graylog: ${error.toString()}`);
    }
  }

  static debug = (message, {fullMessage, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.DEBUG, fullMessage, ...additionalFields}).then();
  };

  static info = (message, {fullMessage, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.INFO, fullMessage, ...additionalFields}).then();
  };

  static notice = (message, {fullMessage, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.NOTICE, fullMessage, ...additionalFields}).then();
  };

  static warning = (message, {fullMessage, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.WARNING, fullMessage, ...additionalFields}).then();
  };

  static error = (message, {fullMessage, error, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.ERROR, fullMessage, error, ...additionalFields}).then();
  };

  static critical = (message, {fullMessage, error, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.CRITICAL, fullMessage, error, ...additionalFields}).then();
  };

  static alert = (message, {fullMessage, error, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.ALERT, fullMessage, error, ...additionalFields}).then();
  };

  static emergency = (message, {fullMessage, error, ...additionalFields} = {}) => {
    Graylog.log(message, {level: GRAYLOG_LOG_LEVEL.EMERGENCY, fullMessage, error, ...additionalFields}).then();
  };
}
