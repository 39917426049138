import $ from "jquery";
import ZPL, { Size } from "jszpl";
import { PNG } from "pngjs";

import { log, logError } from "./common";
import { Graylog } from "./graylog";

const BLUETOOTH_PRINTER_MAC_ADDRESS = "00:07:4D:DB:68:90";
const WIRED_PRINTER_MAC_ADDRESS = "00:07:4D:DB:6E:B8";
const WIRED_PRINTER_IP_ADDRESS = "192.168.178.53";

const MAX_RETRIES = 5;

const LABEL_WIDTH = 300;
const LABEL_HEIGHT = 150;

export class PrinterManager {
  currentRetry = 0;

  static printers;

  searchPrinters() {
    $("#printer-print-status").removeClass("error").empty();

    if (this.currentRetry == 0) {
      PrinterManager.printers = [];
    }

    this.currentRetry++;

    if (PrinterManager.printers.length === 0) {
      if (this.currentRetry <= MAX_RETRIES) {
        $("#printer-status").text("Searching ..." + (this.currentRetry > 1 ? ` (try ${this.currentRetry}/${MAX_RETRIES})` : ""));
        $("#printer-status-icon").show();
        $("#printer-button-reconnect").hide();

        this.trySearchPrinters(this.currentRetry);
      } else {
        const errorMessage = `Could not find any printers after ${MAX_RETRIES} retries.`;

        logError(errorMessage);
        Graylog.critical(errorMessage);

        $("#printer-status").text("Not Found");
        $("#printer-status-icon").hide();
        $("#printer-button-reconnect").show();
      }
    }
  }

  trySearchPrinters(currentRetry) {
    const tryString = `${currentRetry}/${MAX_RETRIES}`;

    log(`Looking for printers (try ${tryString}) ...`);

    EB.Printer.searchPrinters({
      connectionType: EB.Printer.CONNECTION_TYPE_TCP,
      printerType: EB.Printer.PRINTER_TYPE_ZEBRA,
      deviceAddress: WIRED_PRINTER_IP_ADDRESS,
    }, result => {
      if (result.status != "PRINTER_STATUS_SUCCESS") {
        log(`Could not find printers (try ${tryString}): ${result.status} "${result.message}"`);

        return this.searchPrinters();
      }

      const printerId = result.printerID;

      if (typeof printerId == "undefined") {
        log(`Found ${PrinterManager.printers.length} printers so far (try ${tryString}).`);

        return this.searchPrinters();
      }

      const printer = EB.Printer.getPrinterByID(printerId);

      const {deviceName, deviceAddress} = printer;

      log(`Found printer: '${deviceName}' | ${deviceAddress}`,);
      Graylog.info(`Found printer: '${deviceName}' | ${deviceAddress}`, {deviceName, deviceAddress});

      if (deviceAddress !== WIRED_PRINTER_IP_ADDRESS) {
        log(`Printer '${deviceName}' is not the wired printer. Skipping ...`);

        return;
      }

      PrinterManager.printers.push(printer);

      this.connectPrinter(printer);
    });
  }

  connectPrinter(printer) {
    const printerName = printer.deviceName;

    log(`Connecting to printer '${printerName}' ...`);

    $("#printer-status").text(`Connecting to ${printerName} ...`);
    $("#printer-status-icon").show();

    printer.connect(status => {
      log(`Connection status for printer '${printerName}': ${status}`);

      if (status === "PRINTER_STATUS_ERROR") {
        $("#printer-status").text("Error");
        $("#printer-status-icon").hide();
        $("#printer-button-reconnect").show();

        const errorMessage = `Could not connect to printer '${printerName}'`;

        logError(errorMessage);
        Graylog.critical(errorMessage);

        return;
      }

      $("#printer-status").text("Connected");

      log(`Requesting printer state for printer '${printerName}' ...`);

      printer.requestState([
        "PRINTER_STATE_IS_READY_TO_PRINT",
      ], result => {
        const {message: resultMessage, status: resultStatus} = result;

        if (resultStatus === "PRINTER_STATUS_SUCCESS") {
          $("#printer-status").text("Ready").attr("data-ready", true);
          $("#printer-connection").text(`Connection: ${printer.deviceName} @ ${printer.deviceAddress}`);
          $("#printer-status-icon").hide();
          $("#printer-button-reconnect").hide();

          log(`PRINTER_STATE_IS_READY_TO_PRINT = ${result.PRINTER_STATE_IS_READY_TO_PRINT}`);

          if ($("#scanner-data").text()) {
            $("#printer-button-print").show();
          }
        } else {
          logError(`Printer '${printerName}' is not ready: ${resultStatus} "${resultMessage}"`);
          Graylog.critical(`Printer '${printerName}' is not ready: ${resultStatus}`, {status: resultStatus, fullMessage: resultMessage});
        }
      });

      log(`Requesting supported control languages for '${printerName}' ...`);

      printer.enumerateSupportedControlLanguages(result => {
        log(`Supported control languages: ${result}`);
      });
    });
  }

  static print() {
    $("#printer-print-status").removeClass("error").text("Printing...");

    const printer = PrinterManager.printers[0];

    if (!printer) {
      logError(`Printing failed: No printer found.`);
      Graylog.error(`Printing failed: No printer found.`);

      return;
    }

    const imageSize = Math.min(LABEL_WIDTH, LABEL_HEIGHT);

    const label = new ZPL.Label();

    label.printDensity = new ZPL.PrintDensity(ZPL.PrintDensityName["12dpmm"]);
    label.width = new Size(LABEL_WIDTH);
    label.height = new Size(LABEL_HEIGHT);

    const [, base64] = $("#scanner-image").attr("src").split(",");

    const imageBuffer = Buffer.from(base64, "base64");
    const imageData = PNG.sync.read(imageBuffer);

    // Use graphic instead of Barcode to ensure consistent size of printed label.
    const graphic = new ZPL.Graphic();

    label.content.push(graphic);

    graphic.width = new Size(imageSize);
    graphic.height = new Size(imageSize);
    graphic.left = new Size((LABEL_WIDTH - imageSize) / 2); // 75
    graphic.top = new Size(10); // found out by trying

    /*
     * The following code is taken from
     * https://github.com/DanieLeeuwner/JSZPL/blob/HEAD/tests/graphics.test.js#L106C11
     */

    let index = 0;

    const imageBits = [];

    for (let y = 0; y < imageData.height; y++) {
      for (let x = 0; x < imageData.width; x++) {

        const red = imageData.data[index++];
        const green = imageData.data[index++];
        const blue = imageData.data[index++];
        const opacity = imageData.data[index++];

        let value = 0;

        if (opacity != 0) {
          value = (((red + green + blue) / 3) < 180) ? 1 : 0;
        }

        imageBits.push(value);
      }
    }

    graphic.data = new ZPL.GraphicData(imageData.width, imageData.height, imageBits);

    const zpl = label.generateZPL();

    log(`Sending command to printer: ${zpl}`);
    Graylog.debug(`Sending command to printer: ${String(zpl).substring(0, 50)}...${String(zpl).substring(String(zpl).length - 50)}`, {zpl});

    printer.printRawString(zpl, {}, ({status, message}) => {
      log(`Print result: ${status}`);

      if (status === EB.PrinterZebra.PRINTER_STATUS_SUCCESS) {
        $("#printer-print-status").text("Print successful");
      }

      if (status === EB.PrinterZebra.PRINTER_STATUS_ERROR) {
        const errorMessage = `Error during print: ${message}`;

        $("#printer-print-status").addClass("error").text(errorMessage);

        logError(errorMessage);
        Graylog.error(errorMessage, {error: message});

        if (message.includes("connection")) {
          $("#printer-connection").empty();
          $("#printer-button-print").hide();
          $("#printer-button-reconnect").show();
        }
      }
    });
  }
}
