import isFunction from "lodash/isFunction";
import noop from "lodash/noop";

import { config } from "./config";

export const log = (message, {useMarkings = true} = {}) => {
  const fullMessage = useMarkings ? `---------- ${message} ----------` : message;

  if (!config.isBrowserDebugStage && typeof EB !== "undefined" && isFunction(EB.Log.info)) {
    EB.Log.info(fullMessage, noop);
  } else {
    console.info(fullMessage);
  }
};

export const logError = (message, {useMarkings = true} = {}) => {
  const fullMessage = useMarkings ? `########## ${message} ##########` : message.toString();

  if (!config.isBrowserDebugStage && typeof EB !== "undefined" && isFunction(EB.Log.error)) {
    EB.Log.error(fullMessage, noop);
  } else {
    console.error(fullMessage);
  }
};

export const isApiLoaded = () => typeof window.EB !== "undefined";
