import $ from "jquery";
import QRCode from "qrcode";
import compact from "lodash/compact";
import sortBy from "lodash/sortBy";

import { log, logError } from "./common";
import { Graylog } from "./graylog";

const BASE_URL = "https://qr.vt1.eu";
const ZEBRA_BLUETOOTH_SCANNER_NAME = "Zebra Bluetooth Scanner";
const ZEBRA_BLUETOOTH_SCANNER_ID = "EMDK5";

$("#url-locale").on("change", () => {
  $("#scanner-image").attr("src", "");
  $("#scanner-data").empty();
  $("#scanner-info").empty();
  $("#scanner-data-container").hide();
  $("#printer-button-print").hide();
});

const onBarcodeScanned = async jsonObject => {
  $("#printer-print-status").removeClass("error").empty();

  const {data, source, time, type} = jsonObject;

  log(`Barcode scanned: ${JSON.stringify(jsonObject)}`);
  log(`Barcode data: ${data}`);
  Graylog.debug(`Barcode scanned: ${data}`, {source, time, type});

  const locale = $("#url-locale").val();
  const urlPath = compact([locale, encodeURIComponent(data)]).join("/");
  const url = new URL(urlPath, BASE_URL);
  const urlString = url.toString();

  $("#scanner-data-container").show();
  $("#scanner-data").text(urlString);
  $("#scanner-info").text(`Source: ${source}, Time: ${time}, Type: ${type}`);

  try {
    const dataUrl = await QRCode.toDataURL(urlString);

    $("#scanner-image").attr("src", dataUrl);

    if ($("#printer-status").data("ready") === true) {
      $("#printer-button-print").show();
    }
  } catch (error) {
    const errorMessage = `Could not generate QR code for '${urlString}': ${error.toString()}`;

    logError(errorMessage);
    Graylog.error(errorMessage, {error});
  }
};

const getScannerDisplayName = scanner => `"${scanner.getFriendlyName()}" | ${scanner.getScannerType()} | ${scanner.getId()}`;

/*
 * ScannerManager is currently unused. Probably, we don't even need it.
 * It may be used to list available scanners and enable / disable them separately.
 */
export class ScannerManager {
  static zebraBluetoothScanner;

  /*
   * List available scanners. This is currently unused.
   */
  static enumerate() {
    const $scanners = $("#scanners");

    $scanners.empty();

    return new Promise((resolve, reject) => {
      const onError = (message) => {
        logError(message);
        Graylog.critical(message);

        $scanners.append($("<li class='error'/>").text(message));

        return reject(message);
      };

      // noinspection JSIgnoredPromiseFromCall
      EB.Barcode.enumerate(scanners => {
        if (scanners.length === 0) {
          return onError("No scanners found!");
        }

        log(`${scanners.length} scanners found!`);

        scanners.forEach((scanner, index) => {
          log(`Scanner ${index + 1}: ${getScannerDisplayName(scanner)}`, {useMarkings: false});
        });

        const zebraBluetoothScanner = scanners.find(scanner => scanner.getId() === ZEBRA_BLUETOOTH_SCANNER_ID);

        if (!zebraBluetoothScanner) {
          return onError(`No "${ZEBRA_BLUETOOTH_SCANNER_NAME}" found!`);
        }

        ScannerManager.zebraBluetoothScanner = zebraBluetoothScanner;

        const scannerDisplayName = getScannerDisplayName(zebraBluetoothScanner);

        log(`"${ZEBRA_BLUETOOTH_SCANNER_NAME}" found: ${scannerDisplayName}`);
        Graylog.info(`"${ZEBRA_BLUETOOTH_SCANNER_NAME}" found: ${scannerDisplayName}`, {scannerDisplayName});

        $scanners.append($("<li />").text(`Scanner: ${scannerDisplayName}`));

        return resolve(scanners);
      });
    });
  }

  static enable() {
    return new Promise((resolve, reject) => {
      if (!ScannerManager.zebraBluetoothScanner && EB.Barcode.getId() !== ZEBRA_BLUETOOTH_SCANNER_ID) {
        const errorMessage = `Error enabling scanner: No "${ZEBRA_BLUETOOTH_SCANNER_NAME}" found!`;

        logError(errorMessage);
        Graylog.critical(errorMessage);

        return reject(errorMessage);
      }

      // Scanner we found or the default one.
      const scanner = ScannerManager.zebraBluetoothScanner || EB.Barcode;
      const scannerName = scanner.getFriendlyName();

      scanner.enable({qrCode: true, scannerType: "Imager"}, onBarcodeScanned);
      scanner.decodeVolume = 1;

      $("#scanner-status").text("Enabled");

      log(`Scanner "${scannerName}" enabled!`);

      resolve(scanner);
    });
  }

  static getSupportedProperties(scanner) {
    const propertiesObject = {};
    const scannerName = scanner.getFriendlyName();

    try {
      log(`"${scannerName}" properties BEGIN`);

      const propertyNames = scanner.getSupportedProperties();
      const properties = scanner.getProperties(propertyNames);

      sortBy(Object.keys(properties)).forEach(key => {
        log(`${key} = ${properties[key]}`, {useMarkings: false});

        propertiesObject[key] = properties[key];
      });

      log(`"${scannerName}" properties END`);
    } catch (error) {
      logError(`Error on getting properties for scanner "${scannerName}": ${error.toString()}`);
      Graylog.error(`Error on getting properties for scanner "${scannerName}"`, {error});
    }

    return propertiesObject;
  }

  static disableAll() {
    EB.Barcode.disable();

    log(`Scanners disabled!`);
  }
}
