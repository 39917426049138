import $ from "jquery";

import { config } from "./modules/config";
import { isApiLoaded, log, logError } from "./modules/common";
import { PrinterManager } from "./modules/printer";
import { ScannerManager } from "./modules/scanner";
import { Graylog } from "./modules/graylog";

const onApiScriptLoaded = async () => {
  if (isApiLoaded()) {
    try {
      await ScannerManager.enumerate();

      const scanner = await ScannerManager.enable();

      // We need to wait for the scanner to be enabled before we can get the supported properties.
      setTimeout(() => ScannerManager.getSupportedProperties(scanner), 5000);
    } catch (error) {
      const errorMessage = `Error on enabling scanners: ${error.toString()}`;

      logError(errorMessage);
      Graylog.critical(errorMessage, {error});
    }

    try {
      const printerManager = new PrinterManager();

      printerManager.searchPrinters();
    } catch (error) {
      const errorMessage = `Error on searching printers: ${error.toString()}`;

      logError(errorMessage);
      Graylog.critical(errorMessage, {error});
    }
  }
};

window.addEventListener("DOMContentLoaded", async () => {
  log("Buschjost Zebra App is running!");
  Graylog.info("Buschjost Zebra App is running!");

  log(`Configuration: ${JSON.stringify(config)}`);
  Graylog.info(`Configuration: ${JSON.stringify(config)}`, config);

  $("#scanner-data-container").hide();
  $("#printer-button-print").hide();

  if (config.isProductionStage) {
    const scriptElement = document.createElement("script");

    scriptElement.onload = onApiScriptLoaded;
    scriptElement.src = "./ebapi-modules.js";

    document.head.appendChild(scriptElement);
  }
});

window.addEventListener("load", () => {
  // placeholder
});

window.addEventListener("beforeunload", () => {
  if (isApiLoaded()) {
    try {
      ScannerManager.disable();
    } catch (error) {
      const errorMessage = `Error on disabling scanners: ${error.toString()}`;

      logError(errorMessage);
      Graylog.critical(errorMessage, {error});
    }
  }
});

window.addEventListener("printer-reconnect", () => {
  if (isApiLoaded()) {
    try {
      const printerManager = new PrinterManager();

      printerManager.searchPrinters();
    } catch (error) {
      const errorMessage = `Error on reconnecting printers: ${error.toString()}`;

      logError(errorMessage);
      Graylog.critical(errorMessage, {error});
    }
  }
});

window.addEventListener("print-qrcode", () => {
  if (isApiLoaded()) {
    try {
      PrinterManager.print();
    } catch (error) {
      const errorMessage = `Error on printing QR code: ${error.toString()}`;

      logError(errorMessage);
      Graylog.critical(errorMessage, {error});
    }
  }
});

window.addEventListener("copy-qrcode", () => {
  const qrcode = $("#scanner-data").text();

  if (qrcode) {
    navigator.clipboard.writeText(qrcode).then(() => {
      log("QR code copied to clipboard!");

      EB.Notification.showPopup({
        message: "QR code copied to clipboard!",
        types: [EB.Notification.TYPE_TOAST]
      });
    });
  }
});

window.onerror = (message, source, lineno, colno, error) => {
  const errorMessage = `Unhandled error: ${message}`;

  logError(errorMessage);
  logError(`Error: ${error}`, {useMarkings: false});
  logError(`Source: ${source}, Line: ${lineno}, Column: ${colno}`, {useMarkings: false});

  Graylog.alert(errorMessage, {source, lineno, colno, error});

  return false;
};

window.addEventListener("error", event => {
  const {error} = event;

  return globalErrorHandler(error, `Unhandled error: ${error.message || error.toString()}`);
});

window.addEventListener("unhandledrejection", event => {
  const {reason: error} = event;

  return globalErrorHandler(error, `Unhandled promise rejection: ${error.message || error.toString()}`);
});

const globalErrorHandler = async (error, message) => {
  logError(message);

  if (error.stack) {
    logError(`Error: ${error.stack}`, {useMarkings: false});
  }

  Graylog.alert(message, {error});

  return false;
};
